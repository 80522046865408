import { useState, useEffect, memo, lazy, Suspense } from "react";
import { injectIntl } from "react-intl";
import { Row, Container } from "reactstrap";
import { Colxx } from "components/common/CustomBootstrap";
import {
  FaFacebookF,
  // FaTwitter,
  FaEnvelope,
} from "react-icons/fa";
// import { FiMapPin, FiPhone } from "react-icons/fi";
import { FadeInLeftSpring } from "components/animations/fadeIntTopSpring";
import { FadeInRightSpringIcons } from "components/animations/fadeIntTopSpringIcons";
import { IoMenuOutline } from "react-icons/io5";
import imageLogo from "assets/img/logo.png";
import { ImageAsyn } from "components/ImageAsyc";
import { NavLink } from "components/NavLink";

const MenuMobile = lazy(() =>
  import(
    /* webpackChunkName: "layout-navWeb-topnav-menu_mobile" */ "./MenuMobile"
  )
);

const TopNav = () => {
  const [showFixed, setShowFixed] = useState(false);
  const [showMenuMobile, setShowMenuMobile] = useState(false);

  useEffect(
    function () {
      const onScroll = () => {
        const newShowFixed = window.scrollY > 60;

        showFixed !== newShowFixed && setShowFixed(newShowFixed);
      };

      document.addEventListener("scroll", onScroll);

      return () => document.removeEventListener("scroll", onScroll);
      /* eslint-disable-next-line react-hooks/exhaustive-deps */
    },
    [showFixed]
  );

  const onShowMenu = () => {
    setShowMenuMobile(!showMenuMobile);
  };
  return (
    <header id="header" className="header-main rsAbsoluteEl">
      <div className="mad_top_header">
        <Container>
          <Row>
            <Colxx sm="12" md="4" lg="4">
              <NavLink to="/web" className="logo text-center">
                <FadeInLeftSpring>
                  <ImageAsyn
                    src={imageLogo}
                    alt="samy spa logo"
                    style={{ width: "7rem" }}
                  />
                </FadeInLeftSpring>
              </NavLink>
            </Colxx>
            <Colxx sm="12" md="8">
              <div className="header_info_section">
                <div className="head_socials">
                  {/* <p>
                    <FiMapPin className="mx-2 color-rosa h6" />
                    <a
                      href="https://www.google.com.bo/maps/@-16.497424,-68.1749784,19.52z?hl=es"
                      rel="noreferrer"
                      target="_blank"
                      className="color-blanco"
                    >
                      Calle Arturo Valle # 3065
                    </a>
                  </p> */}
                  <div className="social_icon_list">
                    <ul className="text-center">
                      <li>
                        <a
                          href="https://www.facebook.com/samyspasalon"
                          rel="noreferrer"
                          target="_blank"
                          className="soc_icon icon-facebook mx-2"
                        >
                          <FadeInRightSpringIcons>
                            <FaFacebookF />
                          </FadeInRightSpringIcons>
                        </a>
                      </li>
                      {/* <li>
                                                <a
                                                    href="http://dominio.com"
                                                    rel="noreferrer"
                                                    target="_blank"
                                                    className="soc_icon icon-twitter mx-2"
                                                >
                                                    <FadeInRightSpringIcons>
                                                        <FaTwitter />
                                                    </FadeInRightSpringIcons>
                                                </a>
                                            </li> */}
                      <li>
                        <a
                          href="mailto:info@samyspa.com"
                          className="soc_icon icon-gplus mx-2"
                        >
                          <FadeInRightSpringIcons>
                            <FaEnvelope />
                          </FadeInRightSpringIcons>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="head_phone">
                  {/* <div className="text-center">
                                        <p>
                                            <FiPhone className="mx-2 color-rosa h6" />
                                            <a
                                                href="tel:+59173073344"
                                                className="color-blanco"
                                            >
                                                +591 73073344
                                            </a>
                                        </p>
                                        {/* <NavLink to='/user/login' className="icon_mobile cursor-pointer text-white">
                                        </NavLink>
                                    </div> */}
                  <NavLink to="/user/login" className="mad_button">
                    Iniciar Sesión
                  </NavLink>
                </div>
              </div>
            </Colxx>
          </Row>
        </Container>
      </div>

      <div className="menu_holder">
        <Container>
          <div className="menu_wrap">
            <div className="nav_item d-sm-none d-lg-inline-block">
              <nav id="main_navigation" className="main_navigation">
                <ul id="menu" className="clearfix">
                  <li className="current">
                    <NavLink to="/web">Inicio</NavLink>
                  </li>
                  <li className="">
                    <NavLink to="samyspa">Samy</NavLink>
                  </li>
                  <li className="">
                    <NavLink to="servicios">Servicios</NavLink>
                  </li>
                  <li className="">
                    <NavLink to="contacto">Contacto</NavLink>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="d-sm-block d-lg-none mb-3">
              <IoMenuOutline
                className="cursor-pointer text-white"
                size="2.4rem"
                onClick={onShowMenu}
              />
            </div>
          </div>
        </Container>
      </div>
      {showMenuMobile && (
        <Suspense
          fallback={
            <div>
              <div className="loading" />
            </div>
          }
        >
          <MenuMobile open={showMenuMobile} onShowMenu={onShowMenu} />
        </Suspense>
      )}
    </header>
  );
};

export default memo(injectIntl(TopNav));

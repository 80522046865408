import { createCache, createResource } from "simple-cache-provider";
const cache = createCache();

const ImageResource = createResource(
    (src) =>
        new Promise((resolve) => {
            const img = new Image();
            img.onload = () => resolve(src);
            img.src = src;
        })
);

export function ImageAsyn({ src, alt, ...rest }) {
    return <img src={ImageResource.read(cache, src)} alt={alt} {...rest} />;
}

// import { useState } from 'react';
import { Link } from "@reach/router";

export const NavLink = (props) => {
    return (
    <Link
        {...props}
        getProps={({isCurrent}) => {
            return {className: `${props?.className? props.className:''} text-decoration-none ${isCurrent ? 'text-default-active':''}`}
            // return { style: { color: isCurrent ? "red" : "blue" } };
        }}
    />
)};

import { Children, Fragment } from "react";
import { useTrail, a } from "@react-spring/web";

const TrailRight = ({ children }) => {
    const items = Children.toArray(children);
    const trail = useTrail(items.length, {
        config: { mass: 5, tension: 600, friction: 500 },
        opacity: 1,
        x: 0,
        height: 110,
        from: { opacity: 0, x: 50, height: 0 },
    });
    return (
        <Fragment>
            {trail.map(({ height, ...style }, index) => (
                <a.i key={index} style={style}>
                    {items[index]}
                </a.i>
            ))}
        </Fragment>
    );
};

export const FadeInRightSpringIcons = ({ children }) => {
    return <TrailRight>{children}</TrailRight>;
};

const TrailLeft = ({ children }) => {
    const items = Children.toArray(children);
    const trail = useTrail(items.length, {
        config: { mass: 5, tension: 1500, friction: 500 },
        opacity: 1,
        x: 0,
        height: 110,
        from: { opacity: 0, x: -100, height: 0 },
    });
    return (
        <Fragment>
            {trail.map(({ height, ...style }, index) => (
                <a.i key={index} style={style}>
                    {items[index]}
                </a.i>
            ))}
        </Fragment>
    );
};

export const FadeInLeftSpringIcons = ({ children }) => {
    return <TrailLeft>{children}</TrailLeft>;
};

const TrailTop = ({ children }) => {
    const items = Children.toArray(children);
    const trail = useTrail(items.length, {
        config: { mass: 5, tension: 1200, friction: 500 },
        opacity: 1,
        y: 0,
        height: 110,
        from: { opacity: 0, y: -50, height: 0 },
    });
    return (
        <Fragment>
            {trail.map(({ height, ...style }, index) => (
                <a.i key={index} style={style}>
                    {items[index]}
                </a.i>
            ))}
        </Fragment>
    );
};

export const FadeInTopSpringIcons = ({ children }) => {
    return <TrailTop>{children}</TrailTop>;
};

const TrailDown = ({ children }) => {
    const items = Children.toArray(children);
    const trail = useTrail(items.length, {
        config: { mass: 5, tension: 1500, friction: 500 },
        opacity: 1,
        y: 0,
        height: 110,
        from: { opacity: 0, y: 30, height: 0 },
    });
    return (
        <Fragment>
            {trail.map(({ height, ...style }, index) => (
                <a.i key={index} style={style}>
                    {items[index]}
                </a.i>
            ))}
        </Fragment>
    );
};

export const FadeInDownSpringIcons = ({ children }) => {
    return <TrailDown>{children}</TrailDown>;
};

import { memo, useRef, lazy, Suspense } from "react";
import useVisible from "@tteenbe/hookUseVisible";
import TopNav from "containers/navsWeb/Topnav";
// import Footer from "containers/navsWeb/Footer";

const Footer = lazy(() =>
    import(
        /* webpackChunkName: "views-weblayout-footer" */ "containers/navsWeb/Footer"
    )
);

const WebLayout = (props) => {
    const elemRefFooter = useRef();
    const isVisibleFooter = useVisible(elemRefFooter);

    return (
        <div id="wrapper" className="wrapper_container">
            <TopNav />
            {props.children}
            {/* <Footer /> */}
            <article ref={elemRefFooter}>
                {isVisibleFooter && (
                    <Suspense
                        fallback={
                            <div>
                                <div className="loading" />
                            </div>
                        }
                    >
                        <Footer />
                    </Suspense>
                )}
            </article>
        </div>
    );
};

export default memo(WebLayout);

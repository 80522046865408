import { Children, Fragment } from "react";
import { useTrail, a } from "@react-spring/web";

// import styles from "./style.module.css";

const TrailDefault = ({ children, options }) => {
    const items = Children.toArray(children);
    const trail = useTrail(items.length, {
        config: { mass: 5, tension: 1500, friction: 500 },
        opacity: options?.opacity ?? 1,
        x: options?.x ?? 0,
        height: options?.height ?? 50,
        from: { opacity: 0, x: -150, height: 0 },
    });
    return (
        <Fragment>
            {trail.map(({ height, ...style }, index) => (
                <a.div key={index} style={style}>
                    <a.div style={{ height }}>{items[index]}</a.div>
                </a.div>
            ))}
        </Fragment>
    );
};

export const FadeInDefault = ({ children, options }) => {
    return <TrailDefault options={options}>{children}</TrailDefault>;
};

const TrailRight = ({ children }) => {
    const items = Children.toArray(children);
    const trail = useTrail(items.length, {
        config: { mass: 5, tension: 1200, friction: 500 },
        opacity: 1,
        x: 0,
        height: 110,
        from: { opacity: 0, x: 100, height: 0 },
    });
    return (
        <Fragment>
            {trail.map(({ height, ...style }, index) => (
                <a.div key={index} style={style}>
                    <a.div style={{ height }}>{items[index]}</a.div>
                </a.div>
            ))}
        </Fragment>
    );
};

export const FadeInRightSpring = ({ children }) => {
    return <TrailRight>{children}</TrailRight>;
};

const TrailLeft = ({ children }) => {
    const items = Children.toArray(children);
    const trail = useTrail(items.length, {
        config: { mass: 5, tension: 1500, friction: 500 },
        opacity: 1,
        x: 0,
        height: 110,
        from: { opacity: 0, x: -150, height: 0 },
    });
    return (
        <Fragment>
            {/* <a.div key={index} className={styles.trailsText} style={style}> */}
            {trail.map(({ height, ...style }, index) => (
                <a.div key={index} style={style}>
                    <a.div style={{ height }}>{items[index]}</a.div>
                </a.div>
            ))}
        </Fragment>
    );
};

export const FadeInLeftSpring = ({ children }) => {
    return <TrailLeft>{children}</TrailLeft>;
};

const TrailTop = ({ children }) => {
    const items = Children.toArray(children);
    const trail = useTrail(items.length, {
        config: { mass: 5, tension: 1200, friction: 500 },
        opacity: 1,
        y: 0,
        height: 230,
        from: { opacity: 0, y: -100, height: 0 },
    });
    return (
        <Fragment>
            {trail.map(({ height, ...style }, index) => (
                <a.div key={index} style={style}>
                    <a.div style={{ height }}>{items[index]}</a.div>
                </a.div>
            ))}
        </Fragment>
    );
};

export const FadeInTopSpring = ({ children }) => {
    return <TrailTop>{children}</TrailTop>;
};

const TrailDown = ({ children }) => {
    const items = Children.toArray(children);
    const trail = useTrail(items.length, {
        config: { mass: 5, tension: 1500, friction: 500 },
        opacity: 1,
        y: 0,
        height: 110,
        from: { opacity: 0, y: 70, height: 0 },
    });
    return (
        <Fragment>
            {trail.map(({ height, ...style }, index) => (
                <a.div key={index} style={style}>
                    <a.div style={{ height }}>{items[index]}</a.div>
                </a.div>
            ))}
        </Fragment>
    );
};

export const FadeInDownSpring = ({ children }) => {
    return <TrailDown>{children}</TrailDown>;
};
